import * as types from './types';
import createFetchAction from '../createFetchAction';

export const actionCreators = {
  getCardsForAccount: (accountNumber, locationId) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/customer/byaccount/${accountNumber}/cards${locationId && `?locationId=${locationId}`}`,
      startAction: types.GET_CARDS_BY_ACCOUNT_NUMBER 
    });
  },
  canAccountSaveCreditCards: (accountNumber) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/customer/byaccount/${accountNumber}/cansave`,
      startAction: types.GET_CAN_SAVE_CREDIT_CARD
    });
  },
  addCard: (cardData, nonce, cardholderName, saveCard, accountNumber, locationId, externalSystem) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/customer/savecard',
      startAction: types.ADD_CARD,
      body: {
        squareCardNonce: nonce,
        oneTimeCustomer: !saveCard,
        accountNumber: accountNumber,
        cardholderName: cardholderName,
        billingPostalCode: cardData['billing_postal_code'],
        locationId: locationId,
        externalSystem
      }
    });
  },
  addTestCard: (fakeCardId, cardholderName, saveCard, accountNumber, locationId, externalSystem) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/customer/savetestcard',
      startAction: types.ADD_CARD,
      body: {
        fakeCardId: fakeCardId,
        oneTimeCustomer: !saveCard,
        accountNumber: accountNumber,
        cardholderName: cardholderName,
        locationId: locationId,
        externalSystem
      }
    });
  },
  preAuthSalesOrder: (salesId, accountNumber, amount, currencyCode, locationId, cardId) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/salesorderpreauth/${salesId}/${accountNumber}/${amount}/${currencyCode}`,
      startAction: types.PRE_AUTH_SALES_ORDER,
      body: {
        cardId,
        locationId, 
      }
    });
  },
  preAuthServiceOrder: (serviceOrderId, accountNumber, amount, currencyCode, locationId, cardId) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/servicepreauth/${serviceOrderId}/${accountNumber}/${amount}/${currencyCode}/${locationId}`,
      startAction: types.PRE_AUTH_SERVICE_ORDER,
      body: {
        cardId,
      }
    });
  },
  preAuthGenericOrder: (orderId, accountNumber, amount, currencyCode, locationId, cardId, transactionType, externalIdType, externalSystem) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/preauth`,
      startAction: types.PRE_AUTH_SERVICE_ORDER,
      body: {
        accountNumber,
        amount,
        currencyCode,
        locationId,
        cardId,
        orderId,
        transactionType,
        externalIdType,
        externalSystem
      }
    });
  },
  journalCharge: (journalId, accountNumber, amount, currencyCode, locationId, cardId, externalSystem) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/journalcharge/${journalId}/${accountNumber}/${amount}/${currencyCode}/${locationId}`,
      startAction: types.JOURNAL_CHARGE,
      body: {
        cardId,
        externalSystem
      }
    });
  },
  deleteCard: (card) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/customer/deletecard/${card.id}`,
      startAction: types.DELETE_CARD,
      actionPayload: {
        id: card.id,
      }
    });
  },
  inviteToPay: (transactionType, email, amount, locationId, currencyCode, accountNumber, externalIds, externalSystem) => {
    let chargeType = 0;  
    switch (transactionType) {
      case 0: //sales order
        chargeType = 0; //dollar preauth
        break;
      case 1: //service order
        chargeType = 2; //full preauth
        break;
      case 2: //journal
        chargeType = 1; //charge
        break;
      default:
        chargeType = 0; //dollar preauth
        break;
    }
    
    return createFetchAction({
      method: 'PUT',
      url: '/api/v1/invitetopay',
      startAction: types.INVITE_TO_PAY,
      body: {
        accountNumber,
        amount,
        currencyCode,
        locationId,
        chargeType,
        transactionType,
        email,
        externalIds,
        externalSystem
      }
    });
  },
  setSelectedCard: (card) => {
    return {
      type: types.SET_SELECTED_CARD, 
      card: card 
    };
  },
};